<template>
  <v-app class="bg-login">
    <v-row class="ma-0 justify-center">
      <v-col
        class="d-flex justify-center align-center"
        cols="12"
        md="8"
      >
        <v-card
          elevation="8"
          width="100%"
          class="overflow-hidden"
        >
          <v-row>
            <v-col
              class="bg-login-card d-none d-lg-block"
              cols="12"
              lg="5"
            />
            <v-col
              class="px-0"
              cols="12"
              lg="7"
            >
              <v-card-title class="ma-0 pa-0">
                <router-link
                  to="/"
                  style="width:100%"
                >
                  <v-img
                    class="mx-auto"
                    src="@/assets/dev/riverine-logo.png"
                    max-height="150"
                    max-width="350"
                  />
                </router-link>
              </v-card-title>
              <router-view />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
  export default {
    name: 'AuthIndex',
    components: {
    },
    data () {
      return {
        tab: null,
      }
    },
  }
</script>
<style lang="sass" scoped>
.bg-login
  background-image: url('../../assets/dev/fondo-bg.jpg')
  background-size: cover
  background-position: center
.bg-login-card
  background-image: url('../../assets/dev/fondo-login-card.jpg')
  background-size: cover
  background-position: center
.tab-login
  color: #cacaca !important
  background-color: lightgoldenrodyellow
.tab-login.v-tab--active
  color: #2970e2 !important
  background-color: gainsboro
</style>
